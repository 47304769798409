import PropTypes from 'prop-types';
import classNames from 'classnames';
import AccessRightTooltip from '@/storychief/components/AccessRightTooltip';
import EntitlementPopover from '@/storychief/components/EntitlementPopover';
import StoryChief from '@/storychief';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import ConditionalWrapper from '@/storychief/components/ConditionalWrapper';

const propTypes = {
  children: PropTypes.node,
  accessRight: PropTypes.string,
  entitlement: PropTypes.string,
  customEntitlementCheck: PropTypes.func,
  entitlementTitle: PropTypes.node,
  entitlementDescription: PropTypes.node,
  tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onEntitlementPopoverShow: PropTypes.func,
  onEntitlementPopoverHide: PropTypes.func,
};
const defaultProps = {
  children: null,
  accessRight: null,
  entitlement: null,
  customEntitlementCheck: null,
  entitlementTitle: null,
  entitlementDescription: null,
  tooltipPlacement: undefined,
  className: '',
  onClick: null,
  onMouseDown: null,
  id: undefined,
  disabled: false,
  onEntitlementPopoverShow: () => {},
  onEntitlementPopoverHide: () => {},
};

function RestrictedButton({
  accessRight,
  entitlement,
  customEntitlementCheck,
  entitlementTitle,
  entitlementDescription,
  className,
  tooltipPlacement,
  children,
  onClick,
  onMouseDown,
  id,
  disabled,
  onEntitlementPopoverShow,
  onEntitlementPopoverHide,
}) {
  const getEntitlement = useEntitlements();
  const hasAccess = !accessRight || StoryChief.accessRight(accessRight);
  const isDisabled = !hasAccess || !isEntitled() || disabled;

  function isEntitled() {
    if (!entitlement) {
      return true;
    }

    if (customEntitlementCheck) {
      return customEntitlementCheck();
    }

    return !!getEntitlement(entitlement);
  }

  return (
    <ConditionalWrapper
      condition={!isEntitled()}
      wrapper={(wrapperChildren) => (
        <EntitlementPopover
          entitlement={entitlement}
          customEntitlementCheck={customEntitlementCheck}
          entitlementTitle={entitlementTitle}
          entitlementDescription={entitlementDescription}
          tooltipPlacement={tooltipPlacement}
          onShow={onEntitlementPopoverShow}
          onHide={onEntitlementPopoverHide}
        >
          {wrapperChildren}
        </EntitlementPopover>
      )}
    >
      <ConditionalWrapper
        condition={isEntitled()}
        wrapper={(wrapperChildren) => (
          <AccessRightTooltip accessRight={accessRight} tooltipPlacement={tooltipPlacement}>
            {wrapperChildren}
          </AccessRightTooltip>
        )}
      >
        <button
          type="button"
          id={id}
          className={classNames(className, { 'disabled cursor-not-allowed': isDisabled })}
          disabled={isDisabled}
          onClick={!isDisabled && onClick ? onClick : undefined}
          onMouseDown={onMouseDown || undefined}
        >
          {children}
        </button>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}

RestrictedButton.propTypes = propTypes;
RestrictedButton.defaultProps = defaultProps;

export default RestrictedButton;
